import React from "react";

const Testimonial = ({ item, isReadMoreEnabled, handleReadMore }) => {
    return (
        <div className="testimonial-container">
            <div className="with-stars">
                <div className="reviewer-details">
                    <img
                        style={{ borderRadius: 50, border: "3px solid #8ffd2a",  objectFit: "cover" }}
                        className="reviewer-image"
                        src={item.imageSrc}
                        alt="Reviewer"
                        width="45px"
                        height="45px"
                    />
                    <div className="reviewer-about">
                        <div className="reviewer-name">{item.by}</div>
                        <div className="reviewer-company">{item.company}</div>
                    </div>
                </div>
                <div className="stars">
                    <span className={`star star-${item.rating}`}></span>
                </div>
            </div>
            <p className="testimonial-message">
                {isReadMoreEnabled ? item.message : `${item.message.substring(0, 550)}...`}
                {item.message.length > 550 && !isReadMoreEnabled && 
                <>
                    <br/>
                    <div className="readmore">
                        <button2

                            onClick={handleReadMore}
                        >
                            Read more
                        </button2>
                        <img
                            style={{ color: "black",  alignContent: "center", cursor: "pointer" }}
                            src={item.iconSrc}
                            alt="the-insane-studio testimonials"
                            width="15px"
                            height="15px"
                            onClick={handleReadMore}
                        />
                    </div>
                </>}
            </p>
        </div>
    );
};

export default Testimonial;
