import React, { useEffect } from "react";
import Artifea from "../assets/images/best/Artifea.webp";
import RankGenics from "../assets/images/best/RankGenics.webp";
import Markhor from "../assets/images/best/Markhor.webp";
import Cards from "../assets/images/best/Cards.webp";
import Box from "../assets/images/best/Box.webp";
import Uktrade from "../assets/images/best/Uktrade.webp";


const Nscroller = () => {
  // Placeholder images for recent graphic design work
  const recentDesignImages = [Artifea, RankGenics, Markhor, Cards, Box, Uktrade];

  useEffect(() => {
    addAnimation(); // Call addAnimation when the component is mounted
  }, []); // The empty dependency array ensures it runs only once when mounted

  function addAnimation() {
    const scrollers = document.querySelectorAll(".Nscroller");

    scrollers.forEach((Nscroller) => {
      // add data-animated="true" to every `.scroller` on the page
      Nscroller.setAttribute("data-animated", true);

      // Make an array from the elements within `.scroller-inner`
      const NscrollerInner = Nscroller.querySelector(".Nscroller__inner");
      const NscrollerContent = Array.from(NscrollerInner.children);

      // For each item in the array, clone it
      // add aria-hidden to it
      // add it into the `.scroller-inner`
      NscrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        NscrollerInner.appendChild(duplicatedItem);
      });
    });
  }

  return (
    <div className="Background-scroller">
      <div className="text-scroller">
        <div className="graphics-design-button"> Graphics Design</div>
        <h2>OUR BEST WORK</h2>
        <div className="Nscroller" data-direction="right">
          <div className="Nscroller__inner">
            {recentDesignImages.map((image, index) => (
                <img loading="lazy" key={index} src={image} alt="" className="fixedImage" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nscroller;