import logo from '../assets/images/logo.png'


const Footer = () => {
    return (
        <footer>
            <div className='footerclass'>
            <div className='get-in-touch'>
            <div>Get In Touch With Us <br/> info@insanestudios.com</div>
            <div className='contact-info'>
                    <address>Islamabad, Pakistan 44000<br/>Phone: +(92) 317-8045235</address>
                    <></>
                </div>
            </div>

            <div className='footer-image'>
                <img src={logo} alt="the insane studio" width={150} height={150}/>

                
            </div>
            </div>
            <div className='copyright'>&copy; 2023 theinsanestudios.com</div>
        </footer>
    )
}

export default Footer