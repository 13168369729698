import Fiverr from "../assets/images/Fiverr.png"
import Upwork from "../assets/images/Upwork.png"
import Freelancer from "../assets/images/Freelancer.png"

const Companies = () => {
    return(
        <div className="companies">
            <div className="company-logo"><img src={Fiverr} alt="Fiverr"/></div>
            <div className="company-logo"><a href="https://www.upwork.com/freelancers/~0191be85710a754aa4" target="blank"><img src={Upwork} alt="Upwork"/></a></div>
            <div className="company-logo"><img src={Freelancer} alt="Freelancer"/></div>
    </div>
             
    
    )

}

export default Companies