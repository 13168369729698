import CountUp from "react-countup"

const Stats = () => {
    return (
        <div className="stats">
            <div><div className="stat-purple">Clients</div> 
            <CountUp end={100} duration={5} suffix="+" />Satisfied</div>
            <div><div className="stat-purple">Projects</div> <CountUp end={400} duration={5} suffix="+" />Delivered</div> 
            <div><div className="stat-purple">Reviews</div> <CountUp end={100} duration={5} suffix="+" /> Acheived</div>
        </div>
    )
}

export default Stats