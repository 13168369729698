import React, { useState, useEffect } from "react";
import Testimonial from "./Testimonial";
import "./TestimonialsCarousel.css";

const TestimonialsCarousel = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isReadMoreEnabled, setIsReadMoreEnabled] = useState(false);

  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    setIsReadMoreEnabled(false); // Reset isReadMoreEnabled when navigating to the next slide
  };

  const goToPrevSlide = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
    setIsReadMoreEnabled(false); // Reset isReadMoreEnabled when navigating to the previous slide
  };

  useEffect(() => {
    // Use setInterval to automatically go to the next slide every 6 seconds
    const interval = setInterval(() => {
      if (!isReadMoreEnabled) {
        goToNextSlide();
      }
    }, 6000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [activeIndex, isReadMoreEnabled]);

  useEffect(() => {
    // Reset isReadMoreEnabled when the active index changes
    setIsReadMoreEnabled(false);
  }, [activeIndex]);

  return (
    <div className="testimonial-section">
      <h1 className="heading-testimonial">Testimonials</h1>
      <div className="with-video">
        <div className="arrows-carousel-dots">
          <div className="arrow-left" onClick={goToPrevSlide}></div>
          <div className="carousel-container">
            <div className="carousel-wrapper">
              <div className="carousel">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="testimonial-slide"
                    style={{ transform: `translateX(${-(100 * activeIndex)}%)`
                   }}
                  >
                    <Testimonial
                      item={item}
                      isReadMoreEnabled={isReadMoreEnabled}
                      handleReadMore={() => setIsReadMoreEnabled(true)}
                      handleReadLess={() => setIsReadMoreEnabled(false)}
                    />
                  </div>
                ))}
              </div>
              
            </div>
            <div className="carousel-dots">
              {items.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${activeIndex === index ? "active" : ""}`
                }
                />
              ))}
            </div>
          </div>
          <div className="arrow-right" onClick={goToNextSlide}></div>
        </div>
        <div className="video-styling">
        <div className="testimonial-video">
          <iframe
            width="560" height="315"
            src={`https://www.youtube.com/embed/NHTfNBl8P2Q?si=UF1Yj02Sgc_HPbzR&start=4&rel=0`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
