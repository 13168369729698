import { Link } from "react-router-dom"
import React, { useEffect } from "react";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos"; // Import AOS library
import oursevicesbackground from "../assets/images/oursevicesbackground.png"
import graphicsdesign from "../assets/images/graphicsdesign.jpg"
import videediting from "../assets/images/videediting.png"
import productphotography from "../assets/images/productphotography.png"
import productvideography from "../assets/images/productvideography.png"
import webdevelopment from "../assets/images/webdevelopment.png"
import flutterdevelopment from "../assets/images/flutterdevelopment.png"
import visualiser from "../assets/images/visualiser.png"
import uiuxdesign from "../assets/images/uiuxdesign.png"


 


const Service = ({ service }) => {
    const serviceStyle = {
        // Apply the custom styles from the service object's style property
        ...service.style,

    };
    useEffect(() => {
        AOS.init(); // Initialize AOS
    
        // You can set a delay and duration for the animation (optional)
        AOS.init({ duration: 1000, delay: 100 });
    
        // Add the data-aos attribute to trigger the "fade-up" animation
      }, []);

    return(
        <div className="outer-shadow-box">
            <Link to={service.link} target="_blank" rel="noopener noreferrer">
                 <div className="service" style={serviceStyle}>
                 <div class="overlay2"></div>
                    <img src={service.image} alt={`Image for ${service.name}`} />
                    
                    <div className="service-name">{service.name}</div>
                    <div className="service-number">{service.number}</div>
                </div>
            </Link>
        </div>
    )

}

const Services = () =>{
    
    const serviceData = [
        { name: 'Graphics Design', number:1, link: 'https://www.behance.net/ammarhaiderofficial',image: graphicsdesign, style: {
            backgroundcolor: 'white',
            // Add other custom styles as needed
        },},
        { name: 'Video Editing', number:2, link: 'https://www.youtube.com/playlist?list=PLH8-1lGZyFtSu-v3WhQ1vgEmCt-Jb3g56',image: videediting},
        { name: 'Product Photography', number:3, link: 'https://www.behance.net/gallery/182154119/Amazon-Listing-Images-Photography-Design',image: productphotography},
        { name: 'Product Videography', number:4, link: 'https://www.youtube.com/playlist?list=PLH8-1lGZyFtTYGtCIMyerCi2xsBHBSTIt',image: productvideography},
        /*{ name: 'Web Development', number:5, link: '',image: webdevelopment},*/
        { name: 'Web Design', number:5, link: 'https://www.behance.net/gallery/182314749/Landing-Page-Design-Figma-Design',image: flutterdevelopment},
        { name: 'Flutter Development', number:6, link: 'https://www.linkedin.com/in/usman-khalid16/',image: uiuxdesign},
        { name: '3D Visualization', number:7, link: 'https://www.behance.net/alisalmankhalid',image: visualiser},
    ]
    
    return (
        <div id="our-services">
             <div className="background-container">
                <img class="ourservices-background-img" 
                    src={oursevicesbackground}
                    alt="Background Image"
                />
            </div>
            <h1 className="service-heading">OUR SERVICES</h1>
            
            <div className="services" data-aos="fade-up">
                { serviceData.map((service, index) => <Service key={`${service}-${index}`} service={service} />) }
            </div>
        </div>
    )
}


export default Services