import React, { useEffect } from "react";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos"; // Import AOS library
import teamMemberAmmar from "../assets/images/team/Ammar.webp";
import teamMemberAli from "../assets/images/team/Ali.webp";
import teamMemberUsman from "../assets/images/team/Usman.webp";
import teamMemberMubashir from "../assets/images/team/Mubashir.webp";
import logo from "../assets/images/Insane-Logo.png";

const TeamCard = ({ name, title, intro, imageSrc, socialLinks, aosDirection, aosDelay }) => {
  useEffect(() => {
    AOS.init(); // Initialize AOS

    // You can set a delay and duration for the animation (optional)
    AOS.init({ duration: 1000, delay: 100 });

    // Add the data-aos attribute to trigger the "fade-up" animation
  }, []);
  return (
    <div className="team-card" data-aos={`fade-${aosDirection}`} data-aos-delay={aosDelay}>
      <div className="about-section">
        <div className="about-section-image">
          <div className="circle"></div>
          <div className="about-section-image-inner">
            <img src={imageSrc} alt={`${name}-the-insane-studio`} />
          </div>
        </div>
        <div className="about-section-text">
          <div className="name-social-icons-wrapper">
            <div className="name">{name}</div>
            <div className="social-icons">
              {socialLinks.linkedIn && (
                <div className="icon">
                  <a
                    href={socialLinks.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </div>
              )}
              {socialLinks.instagram && (
                <div className="icon">
                  <i className="fa-brands fa-instagram"></i>
                </div>
              )}
              {socialLinks.facebook && (
                <div className="icon">
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
              )}
              {socialLinks.dribble && (
                <div className="icon">
                  <i className="fa-brands fa-square-dribbble"></i>
                </div>
              )}
              {socialLinks.behance && (
                <div className="icon">
                  <a
                    href={socialLinks.behance}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-behance"></i>
                  </a>
                </div>
              )}
              {socialLinks.gitHub && (
                <div className="icon">
                  <a
                    href={socialLinks.gitHub}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-github"></i>
                  </a>
                </div>
              )}
              {socialLinks.itchIo && (
                <div className="icon">
                  <a
                    href={socialLinks.itchIo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-itch-io"></i>
                  </a>
                </div>
              )}
              {socialLinks.stackOverflow && (
                <div className="icon">
                  <a
                    href={socialLinks.stackOverflow}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-stack-overflow"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="title">{title}</div>
          <div
            className="intro"
            dangerouslySetInnerHTML={{ __html: intro.join("<br />") }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const Team = () => {
  const team = [
    {
      name: "Ammar Haider",
      title: "CEO/Founder - Digital Content Creator",
      intro: [
        `The Founder of Insane Studios, as the visionary at our creative company, I take immense pride in guiding a team that is wholeheartedly dedicated to providing top-notch digital services. I'm dedicated to providing Digital Content Creation services myself and overlook the creative team and confident we can make a lasting impact in the ever-evolving digital landscape. My role is to enhance our digital products, ensuring they align seamlessly with our mission of delivering top-quality services to our valued clients`,
      ],
      imageSrc: teamMemberAmmar,
      socialLinks: {
        linkedIn: "https://www.linkedin.com/in/editing-designer-expert/",
        behance: "https://www.behance.net/ammarhaiderofficial",
        
      },
      aosDirection:"right",
    },
    {
      name: "Usman Khalid",
      title: "Flutter Dev - Game Developer",
      intro: [
        `I'm Usman Khalid, a freelance Flutter app developer with a passion for tech and gaming. I specialize in crafting user-friendly mobile applications that combine innovation and functionality. Whether you have a unique app idea or want to enhance an existing project, I'm here to bring your vision to life. Let's collaborate to create captivating and functional mobile apps together. Explore my portfolio and get in touch to discuss your app development needs.`,
      ],
      imageSrc: teamMemberUsman,
      socialLinks: {
        linkedIn: "https://www.linkedin.com/in/usman-khalid16/",
        gitHub: "https://github.com/Usmankhalid16",
        itchIo: "https://insane-game-studio.itch.io/",
        
      },
      aosDirection:"left",
      aosDelay:250,
    },
    {
      name: "Ali Salman Khalid",
      title: "3d Visualizer",
      intro: [
        `I'm a Civil Engineering student with a strong foundation in 2D drawing, 3D modeling, realistic visualization, and precise project estimating. Beyond that, with Five years of experience in audio production, I’m skilled in sound design, audio post-production, and mixing and mastering. My work combines technical expertise with creative problem-solving, bringing both precision and artistry to each project.`,
      ],
      imageSrc: teamMemberAli,
      socialLinks: {
        linkedIn: "https://www.linkedin.com/in/ali-salman-khalid-838531288/",
        behance: "https://www.behance.net/alisalmankhalid",
        
      },
      aosDirection:"right",
      aosDelay:250,
    },
    {
        name: "Mubashir A. Malik",
        title: "Full-stack Web Developer",
        intro: [`Software engineer with years of experience in full-stack web development, well-versed in React, Angular, Node, Nest, JavaScript, PHP, Go, MySQL, and Postgres. I care more about solving problems and see technologies as just a means to do so. I've worked with a wide range of technologies, in different domains from large equestrian sports and event management to HR & Payroll systems. Even if I haven't worked with a specific language, framework, or ecosystem, I'm very quick at picking things up and can slot right in.`],
        imageSrc: teamMemberMubashir,
        socialLinks: {
            linkedIn: "https://www.linkedin.com/in/mubashir0/",
            gitHub: "https://github.com/mubashirmalik",
            stackOverflow: "https://stackoverflow.com/users/11031049/mubashir"
        },
        aosDirection:"left",
        aosDelay:250,
    },
    {
      name: "WANT TO WORK WITH US?",
      title: "Follow us on Linkedin!",
      intro: [
        `Are you passionate about unleashing your creativity in a professional setting? Look no further! Keep your eyes peeled for exciting job opportunities on our LinkedIn profile.
         Your next big career move could be just a click away. Happy hunting! :) `,
      ],
      imageSrc: logo,
      socialLinks: {
        linkedIn: "https://www.linkedin.com/company/theinsanestudio/",
        /*,*/
      },
      aosDirection:"left",
    },
  ];

  return (
    <div className="team-section">
        <div className="meetourteam-heading">
            <div className="meetour">
                MEET OUR 
            </div>
            <div className="team-h">
                TEAM
             </div>
        </div>
    <div className="team-grid">
      <div className="team">
        {team.map((member, index) => (
          <TeamCard key={`team-member-${index + 1}`} {...member} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default Team;
